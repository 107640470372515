import { Box, Typography, InputAdornment } from '@mui/material';
import {
    useTranslate,
    FormDataConsumer,
    Labeled,
    TextField,
    DateField,
    AutocompleteArrayInput,
    ReferenceInput,
    useGetIdentity,
    useGetRecordId,
} from 'react-admin';
import { styled } from '@mui/styles';
import {
    AutocompleteInput,
    TabbedForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceArrayInput,
    ImageInput,
    ImageField,
} from 'react-admin';
import VCardButtonLocal from '../../../components/VCardButtonLocal';
import {
    choicesFoot,
    choicesApplicationType,
} from '../../../types';
import { supabaseConfig } from './../../../supabaseConfig';
import { supabase } from '../../../supabase';
import IbanInput from '../../../components/inputs/IbanInput';

const AutocompleteInputInDialog = styled(AutocompleteInput)({
    suggestionsContainer: { zIndex: 2000 },
});

export const PersonalFormTab = props => {
    const playerId = useGetRecordId();
    const translate = useTranslate();
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    const transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }
        const source = `${supabaseConfig.url}/storage/v1/object/public/youngstarsdb-public/players/${playerId}`;
        const transformedFile = {
            rawFile: {
                path: `players/${playerId}`,
            },
            src: URL.createObjectURL(file),
            source: source,
            title: file.name,
        };

        console.log('transformFile', { transformedFile });
        return transformedFile;
    };
    const multiple = false;
    const transformFiles = (files: any[]) => {
        console.log('transformFiles', { files });
        if (!files) {
            return multiple ? [] : null;
        }

        if (Array.isArray(files)) {
            return files.map(transformFile);
        }

        return transformFile(files);
    };
    if (isLoading) return null;
    return (
        <TabbedForm.Tab
            label="resources.players.tabs.personal"
            path="personal"
            id="personal"
            {...props}
        >
            <Box flex={10} display={{ sm: 'block', md: 'flex' }}>
                <Box flex={5} mr={{ sm: 0, md: '1em' }}>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.identity')}
                    </Typography>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.name"
                                source="name"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="resources.players.fields.birthdate"
                                source="birthdate"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                fullWidth
                                format={v =>
                                    v && typeof v === 'string' && v.length === 4
                                        ? ''
                                        : v
                                }
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                max={2020}
                                min={1980}
                                step={1}
                                label="resources.players.fields.agegroup"
                                source="agegroup"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                label="resources.players.fields.birthplace"
                                source="birthplace"
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                label="resources.players.fields.nationality"
                                source="nationality"
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.parents')}
                    </Typography>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.fatherName"
                                source="fatherName"
                                fullWidth
                            />
                        </Box>
                        <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.fatherMobile"
                                source="fatherMobile"
                                fullWidth
                            />
                        </Box>
                        <FormDataConsumer subscription={{ values: true }}>
                            {({ formData }) =>
                                formData.fatherMobile && (
                                    <Box flex={1} mt={{ xs: 0, sm: '0.5em' }}>
                                        <VCardButtonLocal
                                            playerData={formData}
                                            type="father"
                                        />
                                    </Box>
                                )
                            }
                        </FormDataConsumer>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.motherName"
                                source="motherName"
                                fullWidth
                            />
                        </Box>
                        <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.motherMobile"
                                source="motherMobile"
                                fullWidth
                            />
                        </Box>
                        <FormDataConsumer subscription={{ values: true }}>
                            {({ formData }) =>
                                formData.motherMobile && (
                                    <Box flex={1} mt={{ xs: 0, sm: '0.5em' }}>
                                        <VCardButtonLocal
                                            playerData={formData}
                                            type="mother"
                                        />
                                    </Box>
                                )
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <TextInput
                            sx={{
                                '& .MuiFilledInput-input': {
                                    background: '#ffe5e3',
                                },
                            }}
                            label="resources.players.fields.legal_representative"
                            source="legal_representative"
                            fullWidth
                        />
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                InputProps={{
                                    autoComplete: 'off',
                                }}
                                label="resources.players.fields.account_holder"
                                source="account_holder"
                                fullWidth
                            />
                        </Box>
                        <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                            <IbanInput label="resources.players.fields.iban" source="iban_decr" />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flex={5}
                    ml={{ xs: 0, md: '1em' }}
                    mt={{ xs: '1em', md: 0 }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.adress')}
                    </Typography>
                    <TextInput
                        label="resources.players.fields.adress"
                        source="adress"
                        sx={{
                            '& .MuiFilledInput-input': {
                                background: '#ffe5e3',
                            },
                        }}
                        fullWidth
                    />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="resources.players.fields.postal"
                                source="postal"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.city"
                                source="city"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={4} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.mobile"
                                source="mobile"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                        background: '#ffe5e3',
                                    },
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={4} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.phone"
                                source="phone"
                                fullWidth
                            />
                        </Box>
                        <FormDataConsumer subscription={{ values: true }}>
                            {({ formData }) =>
                                (formData.mobile ||
                                    formData.phone) /*&& logValue(formData)*/ && (
                                    <Box flex={1} mt={{ xs: 0, sm: '0.5em' }}>
                                        <VCardButtonLocal
                                            playerData={formData}
                                            type="player"
                                        />
                                    </Box>
                                )
                            }
                        </FormDataConsumer>
                    </Box>

                    <TextInput
                        label="resources.players.fields.email"
                        source="email"
                        sx={{
                            '& .MuiFilledInput-input': {
                                background: '#ffe5e3',
                            },
                        }}
                        fullWidth
                    />

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <TextInput
                            label="resources.players.fields.passNo"
                            source="passNo"
                            fullWidth
                        />
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <FormDataConsumer subscription={{ values: true }}>
                            {({ formData }) =>
                                formData.applicationType && (
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            label="resources.players.fields.jerseyNo"
                                            source="jerseyNo"
                                        />
                                    </Box>
                                )
                            }
                        </FormDataConsumer>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="resources.players.fields.applicationType"
                                source="applicationType"
                                choices={choicesApplicationType}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {/* <TextInput
                                label="resources.players.fields.comment"
                                multiline
                                source="comment"
                                fullWidth
                            /> */}
                            <ImageInput
                                source="photo"
                                label="Photo"
                                accept="image/*"
                                parse={transformFiles}
                                options={{
                                    onDrop: acceptedFiles => {
                                        acceptedFiles.forEach(async file => {
                                            console.log('uploading file', {
                                                file,
                                            });
                                            const filename = encodeURIComponent(
                                                playerId
                                            );
                                            const { data, error } =
                                                await supabase.storage.from(
                                                    'youngstarsdb-public'
                                                )
                                                    .upload(
                                                        `players/${filename}`,
                                                        file,
                                                        {
                                                            cacheControl:
                                                                '3600',
                                                            upsert: true,
                                                        }
                                                    );
                                            console.log('uploaded file', {
                                                data,
                                                error,
                                                file
                                            });
                                        });
                                    },
                                }}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box flex={10} display={{ sm: 'block', md: 'flex' }}>
                <Box flex={5} mr={{ sm: 0, md: '1em' }}>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.football')}
                    </Typography>

                    <SelectInput
                        label="resources.players.fields.foot"
                        source="foot"
                        choices={choicesFoot}
                        fullWidth
                    />

                    <ReferenceArrayInput
                        fullWidth
                        resource="player"
                        source="position_ids"
                        reference="positions"
                    >
                        <AutocompleteArrayInput
                            label="resources.players.fields.positions"
                            optionText="name"
                            translateChoice={false}
                        />
                    </ReferenceArrayInput>

                    <ReferenceArrayInput
                        fullWidth
                        resource="player"
                        source="repteam_ids"
                        reference="repteams"
                    >
                        <AutocompleteArrayInput
                            label="resources.players.fields.repTeams"
                            optionText="name"
                            translateChoice={false}
                        />
                    </ReferenceArrayInput>

                    <ReferenceInput
                        source="consultancy_id"
                        reference="consultancies"
                        perPage={10}
                    >
                        <AutocompleteInputInDialog
                            label="resources.players.fields.consultancy"
                            noOptionsText="-"
                            shouldRenderSuggestions={(val: any) => {
                                return val.trim().length > 2;
                            }}
                            filterToQuery={(searchText: any) => ({
                                'name@ilike': `%${searchText}%`,
                            })}
                            optionText="name"
                        />
                    </ReferenceInput>

                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.history')}
                    </Typography>

                    <ReferenceInput
                        source="club_previous_id"
                        reference="clubs"
                        perPage={10}
                    >
                        <AutocompleteInputInDialog
                            label="resources.players.fields.previousClub"
                            noOptionsText="-"
                            shouldRenderSuggestions={(val: any) => {
                                return val.trim().length > 2;
                            }}
                            filterToQuery={(searchText: any) => ({
                                'name@ilike': `%${searchText}%`,
                            })}
                            optionText="name"
                        />
                    </ReferenceInput>

                    <ReferenceInput
                        source="club_id"
                        reference="clubs"
                        perPage={10}
                    >
                        <AutocompleteInputInDialog
                            label="resources.players.fields.currentClub"
                            noOptionsText="-"
                            shouldRenderSuggestions={(val: any) => {
                                return val.trim().length > 2;
                            }}
                            filterToQuery={(searchText: any) => ({
                                'name@ilike': `%${searchText}%`,
                            })}
                            optionText="name"
                        />
                    </ReferenceInput>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="resources.players.fields.memberSince"
                                source="memberSince"
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="resources.players.fields.memberUntil"
                                source="memberUntil"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    {allowedAccess && (
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <DateInput
                                label="resources.players.fields.contractUntil"
                                source="contractUntil"
                                fullWidth
                            />
                        </Box>
                    )}



                </Box>

                <Box
                    flex={5}
                    ml={{ xs: 0, md: '2em' }}
                    mt={{ xs: '1em', md: 0 }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fieldGroups.human')}
                    </Typography>
                    <Box flex={3}>
                        <NumberInput
                            label="resources.players.fields.height"
                            fullWidth
                            source="height"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        cm
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box flex={2}>
                        <NumberInput
                            label="resources.players.fields.heightSitting"
                            fullWidth
                            source="heightSitting"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        cm
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box flex={2}>
                        <NumberInput
                            label="resources.players.fields.weight"
                            fullWidth
                            source="weight"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        kg
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box flex={2}>
                        <NumberInput
                            label="resources.players.fields.shoesize"
                            fullWidth
                            source="shoesize"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        EUR
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box flex={2}>
                        <DateInput
                            label="resources.players.fields.physicaldate"
                            source="physicaldate"
                            fullWidth
                        />
                    </Box>
                    <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <Labeled
                                label="pos.fields.updatedby"
                                mr={{ xs: 0, sm: '0.5em' }}
                            >
                                <TextField source="updatedby" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <Labeled
                                label="pos.fields.lastupdate"
                                mr={{ xs: 0, sm: '0.5em' }}
                            >
                                <DateField
                                    source="lastupdate"
                                    options={{
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }}
                                />
                            </Labeled>
                        </Box>
                    </Box>
                    {/*
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.players.fields.comment')}
                    </Typography>
                    <ReferenceManyField
                        target="player_id"
                        reference="all_notes"
                        sort={{ field: 'date', order: 'DESC' }}
                    >
                        <NotesIterator showStatus reference="notes" />
                    </ReferenceManyField> */}


                    {/* <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.strength"
                                multiline
                                source="strength"
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.players.fields.weakness"
                                multiline
                                source="weakness"
                                fullWidth
                            />
                        </Box>
                    </Box> */}
                </Box>
            </Box>
        </TabbedForm.Tab>
    );
};
