import { IfCanAccess } from "@s-graber/ra-rbac";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Edit,
  TabbedForm,
  useTranslate,
  DeleteButton,
  useNotify,
  usePermissions,
  Toolbar,
  useRedirect,
  useUpdate,
  useCreate,
  useRefresh,
  useGetOne,
  useGetRecordId,
  SaveButton,
  useAuthProvider,
  useRecordContext,
} from "react-admin";
import { PlayerBreadcrumb } from "./PlayerBreadcrumb";
import { AbsenceFormTab } from "./tabs/AbsenceFormTab";
import { CallFormTab } from "./tabs/CallFormTab";
import { PersonalFormTab } from "./tabs/PersonalFormTab";
import { PhysicalFormTab } from "./tabs/PhysicalFormTab";
import { ReviewFormTab } from "./tabs/ReviewFormTab";
import { ScoutingFormTab } from "./tabs/ScoutingFormTab";
import { SprintvalueFormTab } from "./tabs/SprintvalueFormTab";
import { TransferFormTab } from "./tabs/TransferFormTab";
import { fetchFromSupabseFunction } from "../../types";
import { useMsal } from "@azure/msal-react";
import Aside, { NotesList } from "./PlayerEditAside";
import { RelationFormTab } from "./tabs/RelationsFormTab";
import { LongForm } from "@s-graber/ra-form-layout";
import { StatsFormTab } from "./tabs/StatsFormTab";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { Box, Theme } from "@mui/material";

export const calcMirwald = (
  weight: number,
  height: number,
  heightSitting: number,
  age: number
) => {
  if (
    weight === null ||
    height === null ||
    heightSitting === null ||
    age === null
  ) {
    return 0;
  }
  const legLenth = height - heightSitting;
  const mirwald =
    -9.236 +
    0.0002708 * legLenth * heightSitting +
    -0.001663 * age * legLenth +
    0.007216 * age * heightSitting +
    ((0.02292 * weight) / height) * 100;
  return mirwald;
};

const transformPhoto = (data) => {
  console.log("transformPhoto", { data });
  return data.photo && data.photo.title
    ? {
      rawFile: {
        path: `${data.photo.rawFile.path}`,
      },
      src: `${data.photo.source}`,
      title: `${data.photo.title}`,
    }
    : {};
};

export const PlayerEditToolbar = (props) => {
  const { isLoading } = usePermissions();
  const id = useGetRecordId();
  const [update] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirectTo = useRedirect();
  const { getValues } = useFormContext();
  const { data: player } = useGetOne("players", { id });
  const { instance: msalInstance } = useMsal();
  const [jwtToken, setJwtToken] = useState();
  const authProvider = useAuthProvider();

  const computeAgegroup = (data) => {
    if (data?.birthdate && data.birthdate?.length === 10) {
      return `${data.birthdate.substr(0, 4)}`;
    } else if (`${data.agegroup}` !== "undefined") {
      return `${data.agegroup}`;
    } else {
      return "";
    }
  };
  const computeBirthdate = (data) => {
    return (!data?.birthdate ||
      (data.birthdate && data.birthdate?.length === 4)) &&
      `${data.agegroup}` !== "undefined"
      ? `${data.agegroup}`
      : `${data.birthdate}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const jwt = await authProvider.getAccessToken(msalInstance);
      setJwtToken(jwt);
    };
    fetchData();
  }, [authProvider, msalInstance]);

  const save = useCallback(async () => {
    const { id, ...values } = getValues();
    // console.log("PlayerEditToolbar.save", { values });
    const iban_decr = values.iban_decr || '';
    delete values.iban_decr;
    if (iban_decr !== '' && jwtToken && id) {
      // console.log('player-iban', { iban_decr, jwtToken, id, values});
      fetchFromSupabseFunction('players-iban', { player_id: id, iban: iban_decr }, jwtToken)
        .then(response => response.json())
        .then(response => {
          notify('resources.players.actions.iban', {
            type: 'info'
          });
        })
        .catch(e => {
          notify(e.message, { type: 'error' });
        })
        .finally(() => { });
    }
    let addendum = "";
    if (
      player.physicaldate !== values.physicaldate ||
      player.height !== values.height ||
      player.weight !== values.weight
    ) {
      const bmi = values.weight / Math.pow(values.height / 100, 2);
      const momentBirthdate = moment(new Date(values.birthdate));
      const momentPhysicalDate = moment(new Date(values.physicaldate));
      const age = momentPhysicalDate.diff(momentBirthdate, "years", true);
      const mirwald = calcMirwald(
        values.weight,
        values.height,
        values.heightSitting,
        age
      );
      const aphv = mirwald !== 0 ? age - mirwald : 0;
      addendum = "/2";
      const physical = {
        player_id: player.id,
        name: values.name,
        date: values.physicaldate,
        height: values.height,
        heightSitting: values.heightSitting || 0,
        weight: values.weight,
        age: age,
        bmi: bmi,
        aphv: aphv,
      };
      // console.log('physicals', { physical });
      create("physicals", {
        data: physical,
      });
    }
    console.log('PlayerEdit.save.values', { values });
    values.photo = transformPhoto(values);
    values.agegroup = computeAgegroup(values);
    values.birthdate = computeBirthdate(values);
    const { iban_derc, ...restValues } = values
    console.log('PlayerEdit.save.restValues', { restValues });
    update(
      "players",
      { id, data: restValues, previousData: player },
      {
        onSuccess: ({ data: newRecord }) => {
          notify("ra.notification.updated", {
            type: "info",
            messageArgs: { smart_count: 1 },
          });
          redirectTo(`/players/${id}${addendum}`);
          refresh();
        },
      }
    );
  }, [player, jwtToken, id]);
  if (isLoading) return null;
  return (
    <Toolbar {...props}>
      <SaveButton type="button" onClick={save} />
      <IfCanAccess action="delete">
        <DeleteButton
          mutationMode="pessimistic"
          redirect="/players"
          sx={{
            align: "right",
          }}
        />
      </IfCanAccess>
    </Toolbar>
  );
};

const PlayerTitle: React.FC<{ record: { name: string } }> = (props) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.players.name")}
      {props.record ? ` "${props.record.name}"` : ""}
    </span>
  );
};

export const PlayerEdit = (props) => {
  const { isLoading } = usePermissions();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  // const { data: player } = useQueryWithStore(
  //   {
  //     type: "getOne",
  //     resource: "",
  //     payload: { id: record.id },
  //   },
  //   {},
  //   (state) => state.admin.resources.players.data[record.id]
  // );

  const transformData = (data) => {
    console.log("PlayerEdit.transformData", { data });
    const photo = transformPhoto(data);
    delete data.photo;
    return {
      ...data,
      photo
    };
  };
  const onSuccess = ({ data }) => {
    notify("ra.notification.updated", {
      type: "info",
      messageArgs: { smart_count: 1 },
    });
    refresh();
  };
  if (isLoading) return null;
  return (
    <Edit
      mutationMode="pessimistic"
      transform={transformData}
      mutationOptions={{ onSuccess }}
      actions={<PlayerBreadcrumb source="id" />}
      title={<PlayerTitle {...props} />}
      aside={<Aside />}
      sx={{
        '& .RaEdit-card': {
          maxWidth: '65%',
        },
      }}
    >
      <TabbedForm onSubmit={null} toolbar={<PlayerEditToolbar />}>
        <PersonalFormTab />
        <AbsenceFormTab />
        {/* <CallFormTab />
        <ReviewFormTab />
        <ScoutingFormTab /> */}
        <PhysicalFormTab />
        <SprintvalueFormTab />
        <TransferFormTab />
        <StatsFormTab />
        <RelationFormTab />
      </TabbedForm>
      <Box display={{ xs: 'block', xm: 'none', lg: 'none' }} mt={5}>
        {<NotesList />}
      </Box>
    </Edit>
  );
};
